// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  subdomain: "https://reviews-dev.",
  firebaseConfig: {
    apiKey: "AIzaSyAUg93UAy6OS4gmT4YjrtA5io7ukMgPy0w",
    authDomain: "ccl-app-dev.firebaseapp.com",
    databaseURL: "https://ccl-app-dev.firebaseio.com",
    projectId: "ccl-app-dev",
    storageBucket: "ccl-app-dev.appspot.com",
    messagingSenderId: "503091657796",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
