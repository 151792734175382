import { Component } from "@angular/core";
import { WhiteLabelService } from "./services/white-label";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "user-review2";

  constructor(whiteLabelService: WhiteLabelService) {}
}
