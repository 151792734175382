import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

export type WhiteLabel = {
  provider: string;
  logo: string;
  domain: string;
  consoleUrl?: string;
};

const MAPPINGS: { [key: string]: WhiteLabel } = {
  clientChatLive: {
    domain: "clientchatlive.com",
    provider: "Client Chat Live",
    logo: "/assets/imgs/logo-orange.png",
  },
  chatLeadsPro: {
    domain: "chatleadspro.com",
    provider: "Chat Leads Pro",
    logo: "/assets/imgs/logo_focus_digital.png",
  },
};

@Injectable()
export class WhiteLabelService {
  whiteLabel: WhiteLabel;

  constructor() {
    if (document.location.origin.includes("chatleadspro.com")) {
      this.whiteLabel = MAPPINGS["chatLeadsPro"];
    } else {
      this.whiteLabel = MAPPINGS["clientChatLive"];
    }
    this.whiteLabel.consoleUrl =
      environment.subdomain + this.whiteLabel.domain + "/";

    document.title = `${this.whiteLabel.provider} Reviews`;
  }
}
